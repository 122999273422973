<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
            <div class="card-header">
                <div class="card-title pt-3 px-3 d-flex justify-content-between">
                    <div class="breadcrumb-left">
                        <h4>
                            Start scheduling
                        </h4>
                        <div class="breadcrumb-sub-header">
                            <router-link to="/dashboard">Dashboard </router-link>\
                            Start scheduling
                        </div>
                    </div>
                    <div class="breadcrumb-right">
                        <div class="card-toolbar">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2 px-6">
                <v-col cols="12" md="3">
                    <v-text-field
                        label="Enrolment key"
                        outlined
                        dense
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        label="First name"
                        outlined
                        dense
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        label="Middle name"
                        outlined
                        dense
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        label="Surname"
                        outlined
                        dense
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        label="All Exams"
                        outlined
                        dense
                    ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        label="All Session"
                        outlined
                        dense
                    ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        label="All grades"
                        outlined
                        dense
                    ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        label="All teachers"
                        outlined
                        dense
                    ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        label="All Exam Preferences"
                        outlined
                        dense
                    ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        label="All Syllabus"
                        outlined
                        dense
                    ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        label="All Instruments"
                        outlined
                        dense
                    ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        label="All Locations"
                        outlined
                        dense
                    ></v-select>
                </v-col>
            </div>

            <div class="row px-6">
                <v-col cols="12" md="5">
                    <v-btn
                        class="btn btn-primary text-white"
                    >
                        <i class="fa fa-magnifying-glass"></i> Filter
                    </v-btn>
                
                    <v-btn
                        color="orange"
                        class="text-white ml-3"
                    >
                        <i class="fa fa-rotate text-white"></i> &nbsp; Reset Filter
                    </v-btn>
                </v-col>
            </div>

            <v-divider class="my-10"></v-divider>
            
            <div class="row px-6">
                <v-col cols="12" md="4">
                    <v-select
                        label="Assign Schedule"
                        outlined
                        dense
                    ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-btn
                        class="btn btn-primary mt-1 text-white"
                    >
                        Save
                    </v-btn>
                </v-col>
                <v-col cols="12" class="text-right" md="6">
                    <v-btn
                        class="btn btn-primary mt-1 text-white"
                    >
                        Save Selected
                    </v-btn>
                </v-col>
            </div>

            <v-divider class="mt-10 mb-5"></v-divider>

            <div class="card-body">
                <div class="table">
                    <v-skeleton-loader
                        type="table-thead"
                        v-if="false"
                    >
                    </v-skeleton-loader>

                    <v-skeleton-loader
                        v-if="false"
                        type="table-row-divider@25"
                    >
                    </v-skeleton-loader>

                    <table class="table">
                        <thead>
                            <tr class="text-left">
                                <th class="px-3"></th>
                                <th class="px-3">First name</th>
                                <th class="px-3">Syllabus</th>
                                <th class="px-3">Instrument</th>
                                <th class="px-3">Grade</th>
                                <th class="px-3">Location</th>
                                <th class="px-3">Teacher name</th>
                                <th class="px-3">Week/Session</th>
                                <th class="px-3">Exam preference</th>
                                <th class="px-3">Purchase comments</th>
                                <th class="px-3">Unsuitable examiners</th>
                                <th class="pr-3 text-center">Options</th>
                                <th class="pr-3 text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template>
                                <tr>
                                    <td class="px-2">
                                        <v-checkbox
                                        ></v-checkbox>
                                    </td>
                                    <td class="px-2">
                                        <div class="font-size-lg">
                                            Hugh Carlos Mcleod
                                        </div>
                                        <div>
                                            <a href="#">
                                                03024373
                                            </a>
                                        </div>
                                    </td>
                                    <td class="px-2">
                                        Brass
                                    </td>
                                    <td class="px-2">
                                        Trumpet (2020+) Comprehensive
                                    </td>
                                    <td class="px-2">
                                        Grade 2
                                    </td>
                                    <td class="px-2">
                                        Adelaide
                                    </td>
                                    <td class="px-2">
                                        John Doe
                                    </td>
                                    <td class="px-2">
                                        <span>
                                            March Session 1 (Central Venue) 16/03/2022-31/03/2022
                                        </span>
                                    </td>
                                    <td class="px-2">
                                        Weekend exam only
                                    </td>
                                    <td class="px-2">
                                        NA
                                    </td>
                                    <td class="px-2">

                                    </td>
                                    <td class="px-2">
                                        <v-select
                                            outlined
                                            dense
                                        ></v-select>
                                    </td>
                                    <td class="px-2 pr-2 text-center">
                                        <template>
                                            <b-dropdown
                                                size="sm"
                                                variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret
                                                right
                                                no-flip
                                            >
                                                <template v-slot:button-content>
                                                    <i class="ki ki-bold-more-hor"></i>
                                                </template>
                                                <div class="navi navi-hover min-w-md-250px">
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-eye</v-icon>
                                                            </span>
                                                            <span class="navi-text">View Candidate Information</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-eye</v-icon>
                                                            </span>
                                                            <span class="navi-text">View Enroller information</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-cog</v-icon>
                                                            </span>
                                                            <span class="navi-text">Change Candidate Pin</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-edit</v-icon>
                                                            </span>
                                                            <span class="navi-text">Change Candidate Enrolment status</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-edit</v-icon>
                                                            </span>
                                                            <span class="navi-text">Edit candidate details</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-file-pdf</v-icon>
                                                            </span>
                                                            <span class="navi-text">Manage Reports & Result</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-arrow-rotate-left</v-icon>
                                                            </span>
                                                            <span class="navi-text">Update information log</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </template>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-2">
                                        <v-checkbox
                                        ></v-checkbox>
                                    </td>
                                    <td class="px-2">
                                        <div class="font-size-lg">
                                            Hugh Carlos Mcleod
                                        </div>
                                        <div>
                                            <a href="#">
                                                03024373
                                            </a>
                                        </div>
                                    </td>
                                    <td class="px-2">
                                        Brass
                                    </td>
                                    <td class="px-2">
                                        Trumpet (2020+) Comprehensive
                                    </td>
                                    <td class="px-2">
                                        Grade 2
                                    </td>
                                    <td class="px-2">
                                        Adelaide
                                    </td>
                                    <td class="px-2">
                                        John Doe
                                    </td>
                                    <td class="px-2">
                                        <span>
                                            March Session 1 (Central Venue) 16/03/2022-31/03/2022
                                        </span>
                                    </td>
                                    <td class="px-2">
                                        Weekend exam only
                                    </td>
                                    <td class="px-2">
                                        NA
                                    </td>
                                    <td class="px-2">

                                    </td>
                                    <td class="px-2">
                                        <v-select
                                            outlined
                                            dense
                                        ></v-select>
                                    </td>
                                    <td class="px-2 pr-2 text-center">
                                        <template>
                                            <b-dropdown
                                                size="sm"
                                                variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret
                                                right
                                                no-flip
                                            >
                                                <template v-slot:button-content>
                                                    <i class="ki ki-bold-more-hor"></i>
                                                </template>
                                                <div class="navi navi-hover min-w-md-250px">
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-eye</v-icon>
                                                            </span>
                                                            <span class="navi-text">View Candidate Information</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-eye</v-icon>
                                                            </span>
                                                            <span class="navi-text">View Enroller information</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-cog</v-icon>
                                                            </span>
                                                            <span class="navi-text">Change Candidate Pin</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-edit</v-icon>
                                                            </span>
                                                            <span class="navi-text">Change Candidate Enrolment status</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-edit</v-icon>
                                                            </span>
                                                            <span class="navi-text">Edit candidate details</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-file-pdf</v-icon>
                                                            </span>
                                                            <span class="navi-text">Manage Reports & Result</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-arrow-rotate-left</v-icon>
                                                            </span>
                                                            <span class="navi-text">Update information log</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </template>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-2">
                                        <v-checkbox
                                        ></v-checkbox>
                                    </td>
                                    <td class="px-2">
                                        <div class="font-size-lg">
                                            Hugh Carlos Mcleod
                                        </div>
                                        <div>
                                            <a href="#">
                                                03024373
                                            </a>
                                        </div>
                                    </td>
                                    <td class="px-2">
                                        Brass
                                    </td>
                                    <td class="px-2">
                                        Trumpet (2020+) Comprehensive
                                    </td>
                                    <td class="px-2">
                                        Grade 2
                                    </td>
                                    <td class="px-2">
                                        Adelaide
                                    </td>
                                    <td class="px-2">
                                        John Doe
                                    </td>
                                    <td class="px-2">
                                        <span>
                                            March Session 1 (Central Venue) 16/03/2022-31/03/2022
                                        </span>
                                    </td>
                                    <td class="px-2">
                                        Weekend exam only
                                    </td>
                                    <td class="px-2">
                                        NA
                                    </td>
                                    <td class="px-2">

                                    </td>
                                    <td class="px-2">
                                        <v-select
                                            outlined
                                            dense
                                        ></v-select>
                                    </td>
                                    <td class="px-2 pr-2 text-center">
                                        <template>
                                            <b-dropdown
                                                size="sm"
                                                variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret
                                                right
                                                no-flip
                                            >
                                                <template v-slot:button-content>
                                                    <i class="ki ki-bold-more-hor"></i>
                                                </template>
                                                <div class="navi navi-hover min-w-md-250px">
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-eye</v-icon>
                                                            </span>
                                                            <span class="navi-text">View Candidate Information</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-eye</v-icon>
                                                            </span>
                                                            <span class="navi-text">View Enroller information</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-cog</v-icon>
                                                            </span>
                                                            <span class="navi-text">Change Candidate Pin</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-edit</v-icon>
                                                            </span>
                                                            <span class="navi-text">Change Candidate Enrolment status</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-edit</v-icon>
                                                            </span>
                                                            <span class="navi-text">Edit candidate details</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-file-pdf</v-icon>
                                                            </span>
                                                            <span class="navi-text">Manage Reports & Result</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="black darken-2">fas fa-arrow-rotate-left</v-icon>
                                                            </span>
                                                            <span class="navi-text">Update information log</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </div>

    <!-- examiner email list modal -->
    <examiner-confirmation
        ref="examiner-confirmation"
        @refresh="getExamDayInfo"
    ></examiner-confirmation>
    <!-- examiner email list modal ends -->

    <!-- venue confirmation modal box -->
    <venue-confirmation
        ref="venue-confirmation"
        @refresh="getExamDayInfo"
    ></venue-confirmation>
    <!-- venue confirmation modal box ends -->


  </v-app>
</template>

<script>
// import vMultiselectListbox from 'vue-multiselect-listbox';
// import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css';
import ExamDayService from '@/services/practical-exam/exam-day/ExamDayService';
import ExaminerConfirmation from "./ExaminerConfirmation";
import VenueConfirmation from "./VenueConfirmation";

const examDay = new ExamDayService();

export default {
    components:{
        ExaminerConfirmation,
        VenueConfirmation
    },
    data(){
        return {
            menuStartDate: false,
            activePicker: null,
            examDayId: null,
            examDay: {},
            showExaminerEmailDialog: false,
            showVenueConfirmationDialog: false,
            loading: false,
        }
    },
    methods:{
        getExamDayInfo(){
            examDay
            .show(this.examDayId)
            .then((response) => {
                this.examDay = response.data.examDay;
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        },
        sendExaminerConfirmation(){
            this.$refs['examiner-confirmation'].showDialog(this.examDayId);
        },
        sendVenueConfirmation(){
            this.$refs['venue-confirmation'].showDialog(this.examDay);
        }
    },
    mounted() {
        this.examDayId = this.$route.params.examDayId;
        this.getExamDayInfo();
    },
};
</script>
